import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/auth/interfaces/login.interfaces';

@Injectable({
  providedIn: 'root'
})
export class KitsService {
  baseUrl: string = environment.baseUrl;
  public user: User;
  public token: string;
  constructor(private http: HttpClient) {
    this.user = JSON.parse(localStorage.getItem('user')) || {};
    this.token = JSON.parse(localStorage.getItem('access_token'))
  }

  getAll(): Observable<any> {
    return this.http.get(this.baseUrl + '/kits',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }

  getAllByCliente(cliente_hash_id: string): Observable<any> {
    return this.http.get(this.baseUrl + '/kits/cliente/'+ cliente_hash_id,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }

  add(datos): Observable<any> {
    return this.http.post(this.baseUrl + '/kits', datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }

  update(datos, id): Observable<any> {
    return this.http.put(this.baseUrl + '/kits/'+id, datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }

  delete(id): Observable<any> {
    return this.http.delete(this.baseUrl + '/kits/'+id,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  lotesDespacho():Observable<any> {
    return this.http.get(this.baseUrl + '/sellos/lotesdespacho',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  empacados(datos): Observable<any> {
    return this.http.post(this.baseUrl + '/kits/empacados', datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
}
